<template>
  <div
    id="my-info-org"
    class="co-flex-col px-6"
  >
    <span class="text-h5 my-4">{{ title }}</span>
    <v-divider />
    <div class="co-w-full co-h40" />
    <el-form
      ref="form"
      :model="frmData"
      :rules="frmRules"
      label-width="100px"
      label-position="right"
    >
      <el-form-item
        label="名称"
        prop="name"
        required
      >
        <el-input
          v-model="frmData.name"
          placeholder="医院/学校名称"
          maxlength="64"
          show-word-limit
        />
      </el-form-item>
      <el-form-item
        label="科室/专业"
        prop="name"
        required
      >
        <el-input
          v-model="frmData.department"
          placeholder="所在科室/专业"
          maxlength="64"
          show-word-limit
        />
      </el-form-item>
      <el-form-item
        label="所在地"
      >
        <div class="co-flex-row co-items-center co-w-full">
          <el-select
            v-model="frmData.prov"
            placeholder="请选择"
            style="width: 220px;"
            @change="onProvChange"
          >
            <el-option
              v-for="item in provItems"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-select
            v-model="frmData.city"
            placeholder="请选择"
            style="width: 220px; margin-left: 24px;"
          >
            <el-option
              v-for="item in cityItems"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="btnSubmit.loading"
          type="primary"
          style="width: 140px;"
          @click="onSubmit"
        >
          {{ btnSubmit.text }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import user from '@/api/co.user'
  import provs from '@/api/city-data/province'
  import citys from '@/api/city-data/city'

  export default {
    name: 'InfoOrg',
    props: {
      info: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        title: '单位/学校信息',
        frmData: {
          attestation: '',
          name: '',
          department: '',
          address: '',
          prov: '',
          city: '',
        },
        frmRules: {
          name: [
            { required: true, message: '请输入医院/学校名称', trigger: 'blur' },
            { min: 2, max: 64, message: '长度在 2 到 64 个字符', trigger: 'blur' }
          ],
          department: [
            { required: true, message: '请输入所在科室/专业', trigger: 'blur' },
            { min: 2, max: 64, message: '长度在 2 到 64 个字符', trigger: 'blur' }
          ],
        },
        defProv: '32', // 江苏省
        defCity: '3201', // 南京市
        provItems: provs,
        cityItems: [],
        btnSubmit: {
          loading: false,
          text: '保存'
        },
      }
    },
    watch: {
      info: {
        handler (val) {
          let addr = api.comm.trim(val.organizationAddress || '')
          if (addr) {
            addr += '000000'
          } else {
            addr = '320102'
          }

          const prov = addr.substring(0, 2)
          const city = addr.substring(0, 4)
          this.setCityItems(prov)

          this.frmData.name = val.organizationName || ''
          this.frmData.department = val.organizationDepartment || ''
          this.frmData.address = val.organizationAddress || ''
          this.frmData.prov = prov
          this.frmData.city = city
        },
        immediate: true
      }
    },
    methods: {
      getProvIndex (provCode) {
        let result = -1
        for (const i in provs) {
          const src = provs[i]
          if (src.value === provCode) {
            result = parseInt(i, 10)
            break
          }
        }
        return result
      },
      setCityItems (provCode) {
        const provInd = this.getProvIndex(provCode)
        if (provInd >= 0) {
          this.cityItems = citys[provInd]
          const first = this.cityItems[0]
          this.frmData.city = first.value
        }
      },
      onProvChange (val) {
        console.log({ val })
        this.setCityItems(val)
      },
      getData () {
        const result = {
          organizationName: this.frmData.name,
          organizationDepartment: this.frmData.department,
          organizationAddress: this.frmData.city,
        }
        return result
      },
      updateInfo (data) {
        const info = Object.assign({}, this.info, data)
        user.info.save(info)
      },
      toConfirm (data) {
        this.$emit('confirm', data)
      },
      onSubmit () {
        const me = this
        const data = this.getData()
        // console.log({ data })
        const executing = function () {
          me.btnSubmit.loading = true
        }
        const executed = function (res) {
          me.btnSubmit.loading = false
          if (res.status) {
            me.updateInfo(data)
            me.toConfirm(data)
            app.emit(app.event.USER_INFO_CHANGE, data)
            me.$notify({
              title: '成功提示',
              message: '机构信息设置成功！',
              type: 'success'
            })
          } else {
            const message = `错误：${res.data.code} - ${res.data.desc}`
            me.$notify({
              title: '机构信息设置失败',
              message,
              type: 'error'
            })
          }
        }

        user.info.update({
          data,
          executing,
          executed
        })
      },
    }
  }
</script>
